import React from 'react'

const Cart = () => {
  return (
    <div>

<div class="page-header text-center" style={{backgroundImage: "url(/img/page-header-bg.jpg)"}}>
        		<div class="container">
        			<h1 class="page-title">Shopping Cart<span>Shop</span></h1>
        		</div>
        	</div>
            <nav aria-label="breadcrumb" class="breadcrumb-nav">
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Shopping Cart</li>
                    </ol>
                </div>
            </nav>

            <div class="page-content">
            	<div class="cart">
	                <div class="container">
	                	<div class="row">
	                		<div class="col-lg-9">
	                			<table class="table table-cart table-mobile">
									<thead>
										<tr>
											<th>Product</th>
											<th>Price</th>
											<th>Quantity</th>
											<th>Total</th>
											<th></th>
										</tr>
									</thead>

									<tbody>
										<tr>
											<td class="product-col">
												<div class="product">
													<figure class="product-media">
														<a href="/DetailedProduct/0N9A1032a">
															<img src="/img/products/0N9A1032a.jpg" alt="Product image"/>
														</a>
													</figure>

													<h3 class="product-title">
														<a href="/DetailedProduct/0N9A1032a">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
													</h3>
												</div>
											</td>
											<td class="price-col">$250.00</td>
											<td class="quantity-col">
                                                <div class="cart-product-quantity">
                                                    <input type="number" class="form-control" value="1" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/><div class="input-group  input-spinner"><div class="input-group-prepend"><button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button></div><input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append"><button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button></div></div>
                                                </div>
                                            </td>
											<td class="total-col">$250.00</td>
											<td class="remove-col"><button class="btn-remove"><i class="icon-close"></i></button></td>
										</tr>
										<tr>
											<td class="product-col">
												<div class="product">
													<figure class="product-media">
														<a href="/DetailedProduct/0N9A0423a">
															<img src="/img/products/0N9A0423a.jpg" alt="Product image"/>
														</a>
													</figure>

													<h3 class="product-title">
														<a href="/DetailedProduct/0N9A0423a">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
													</h3>
												</div>
											</td>
											<td class="price-col">$250.00</td>
											<td class="quantity-col">
                                                <div class="cart-product-quantity">
                                                    <input type="number" class="form-control" value="1" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none;"}}/><div class="input-group  input-spinner"><div class="input-group-prepend">
                                                        <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button">
                                                        <i class="icon-minus"></i></button></div>
                                                        <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append"><button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button></div></div>
                                                </div>                               
                                            </td>
											<td class="total-col">$250.00</td>
											<td class="remove-col"><button class="btn-remove"><i class="icon-close"></i></button></td>
										</tr>
									</tbody>
								</table>

	                			<div class="cart-bottom">
			            			<div class="cart-discount">
			            				<form action="#">
			            					<div class="input-group">
				        						<input type="text" class="form-control" required="" placeholder="coupon code"/>
				        						<div class="input-group-append">
													<button class="btn btn-outline-primary-2" type="submit"><i class="icon-long-arrow-right"></i></button>
												</div>
			        						</div>
			            				</form>
			            			</div>

			            			<a href="#" class="btn btn-outline-dark-2"><span>UPDATE CART</span><i class="icon-refresh"></i></a>
		            			</div>
	                		</div>
	                		<aside class="col-lg-3">
	                			<div class="summary summary-cart">
	                				<h3 class="summary-title">Cart Total</h3>

	                				<table class="table table-summary">
	                					<tbody>
	                						<tr class="summary-subtotal">
	                							<td>Subtotal:</td>
	                							<td>$500.00</td>
	                						</tr>
	                						<tr class="summary-shipping">
	                							<td>Shipping:</td>
	                							<td>&nbsp;</td>
	                						</tr>

	                						<tr class="summary-shipping-row">
	                							<td>
													<div class="custom-control custom-radio">
														<input type="radio" id="free-shipping" name="shipping" class="custom-control-input"/>
														<label class="custom-control-label" for="free-shipping">Free Shipping</label>
													</div>
	                							</td>
	                							<td>$0.00</td>
	                						</tr>

	                						<tr class="summary-shipping-row">
	                							<td>
	                								<div class="custom-control custom-radio">
														<input type="radio" id="standard-shipping" name="shipping" class="custom-control-input"/>
														<label class="custom-control-label" for="standard-shipping">Standard:</label>
													</div>
	                							</td>
	                							<td>$10.00</td>
	                						</tr>

	                						<tr class="summary-shipping-row">
	                							<td>
	                								<div class="custom-control custom-radio">
														<input type="radio" id="express-shipping" name="shipping" class="custom-control-input"/>
														<label class="custom-control-label" for="express-shipping">Express:</label>
													</div>
	                							</td>
	                							<td>$20.00</td>
	                						</tr>

	                						<tr class="summary-shipping-estimate">
	                							<td>Estimate for Your Country<br/> <a href="dashboard.html">Change address</a></td>
	                							<td>&nbsp;</td>
	                						</tr>

	                						<tr class="summary-total">
	                							<td>Total:</td>
	                							<td>$500.00</td>
	                						</tr>
	                					</tbody>
	                				</table>

	                				<a href="Checkout" class="btn btn-outline-primary-2 btn-order btn-block">PROCEED TO CHECKOUT</a>
	                			</div>

		            			<a href="/" class="btn btn-outline-dark-2 btn-block mb-3"><span>CONTINUE SHOPPING</span><i class="icon-refresh"></i></a>
	                		</aside>
	                	</div>
	                </div>
                </div>
            </div>


    </div>
  )
}

export default Cart