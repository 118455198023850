export const Home = () => {
    return (
        <>
            <div className="intro-slider-container mb-0">
                <div className="intro-slider owl-carousel owl-theme owl-nav-inside owl-light owl-loaded owl-drag" data-toggle="owl" data-owl-options="{&quot;nav&quot;: false, &quot;dots&quot;: false}">
                    <div className="intro-slide">
                        <div className="container-fluid intro-content text-center" style={{ padding: "0" }}>
                            <video autoPlay muted loop id="myVideo">
                                <source src="//lv-vod.fl.freecaster.net/vod/louisvuitton/geo/open/8T5CMGWX2D_HD.mp4" type="video/mp4" />
                            </video>

                        </div>
                    </div>
                    <div className="intro-slide" style={{ backgroundImage: "url(/img/slide-1.jpg)" }}>
                        <div className="container intro-content text-center">
                            <h3 className="intro-subtitle text-white">Don’t Miss</h3>
                            <h1 className="intro-title text-white">Mystery Deals</h1>
                            <div className="intro-text text-white">Exclusively Online</div>
                            <a href="/categories" className="btn btn-primary">Discover NOW</a>
                        </div>
                    </div>
                    <div className="intro-slide" style={{ backgroundImage: "url(/img/slide-2.jpg)" }}>
                        <div className="container intro-content text-center">
                            <h3 className="intro-subtitle text-white">Limited time only</h3>
                            <h1 className="intro-title text-white">Treat your self</h1>
                            <div className="intro-text text-white">Up to 50% off</div>
                            <a href="/categories" className="btn btn-primary">Shop NOW</a>
                        </div>
                    </div>
                </div>
                <span className="slider-loader text-white"></span>
            </div>
            <div className="container" style={{marginTop:"35px"}}>
                <div className="banner-group">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="banner banner-border">
                                <a href="/Category/Men/SubCategory/Tops">
                                    <img src="img/specialEdition.jpg" alt="Banner" />
                                </a>
                                <div className="banner-content">
                                    <h3 className="banner-title" ><a href="#"><span >This Week's<br />
                                        Most Wanted</span></a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="banner banner-border-hover">
                                <button id="btnMaroLink1">
                                    <img src="/img/maro.jpg" alt="Banner" />
                                </button>
                                <div className="banner-content">
                                    <h4 className="banner-subtitle"><a href="#">Free Fire Special Edition</a></h4>
                                    <h3 className="banner-title">
                                        <button id="btnMaroLink2"  ><span>Maro Collection</span> </button></h3>
                                    <a href="#" className="btn btn-outline-primary-2 banner-link">Shop Now<i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                            <div className="banner banner-border-hover">
                                <button id="btnElsadaLink1">
                                    <img src="/img/elsada.jpg" alt="Banner" />
                                </button>
                                <div className="banner-content">
                                    <h4 className="banner-subtitle"><a href="#">Special Edition</a></h4>

                                    <h3 className="banner-title">  <button id="btnElsadaLink2" ><span>MR1 EL SADA</span></button></h3>

                                    <a href="#" className="btn btn-outline-primary-2 banner-link">Discover Now<i className="icon-long-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="video-banner video-banner-bg bg-image text-center" style={{backgroundImage:   "url(/img/number1.png)"}}>
          
                <div className="container">
                    <h3 className="video-banner-title h1 text-white"><span>New Collection</span><strong>Number 1</strong></h3>
                    <a href="https://www.youtube.com/watch?v=_a-THDfbZ3I" className="btn-video btn-iframe"><i className="icon-play"></i></a>
                </div>
            </div>
            <div className="pt-6 pb-6" >
                <div className="container">
                    <div className="banner-set">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-set-content text-center">
                                    <div className="set-content-wrapper">
                                        <h4>Special</h4>
                                        <h2>Refine Your Style.</h2>
                                        <p>Get on our exclusive email list and be the first to hear about sales, coupons, new arrivals and more! </p>
                                        <div className="banner-set-products">
                                            <div className="row">
                                                <div className="products">
                                                    <div className="col-6">
                                                        <div className="product product-2 text-center">
                                                            <figure className="product-media">
                                                                <a href="/products">
                                                                    <img src="/img/products/0N9A1013a.jpg" alt="Product image" className="product-image" />
                                                                </a>
                                                            </figure>
                                                            <div className="product-body">
                                                                <h3 className="product-title"><a href="/products">Rib-knit cardigan</a></h3>
                                                                <div className="product-price">
                                                                    $2499
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="product product-2 text-center">
                                                            <figure className="product-media">
                                                                <a href="/products">
                                                                    <img src="/img/products/0N9A0845a.jpg" alt="Product image" className="product-image" />
                                                                </a>
                                                            </figure>
                                                            <div className="product-body">
                                                                <h3 className="product-title"><a href="/products">Linen-blend trousers</a></h3>
                                                                <div className="product-price">
                                                                    $1999
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="banner-set-image banner-border-hover">
                                    <a href="#">
                                        <img src="/img/products/0N9A0626a.jpg" alt="banner" />
                                    </a>
                                    <div className="banner-content">
                                        <h3 className="banner-title"><span><a href="#">Casual basics and<br />
                                            trendy key pieces.</a></span></h3>

                                        <h4 className="banner-subtitle">in this look</h4>

                                        <h4 className="banner-detail">• Rib-knit cardigan
                                            <br />
                                            • Linen-blend paper bag trousers</h4>
                                        <h4 className="banner-price">$1999 - $4800</h4>
                                        <a href="#" className="btn btn-outline-primary-2 banner-link">buy all</a>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
            <div className="container pt-6 new-arrivals">
                <div className="heading heading-center mb-3">
                    <h2 className="title">New Arrivals</h2>


                    <ul className="nav nav-pills justify-content-center" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="new-all-link" data-toggle="tab" href="#new-all-tab" role="tab" aria-controls="new-all-tab" aria-selected="true">All</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="new-cloth-link" data-toggle="tab" href="#new-cloth-tab" role="tab" aria-controls="new-cloth-tab" aria-selected="false">Clothing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="new-shoes-link" data-toggle="tab" href="#new-shoes-tab" role="tab" aria-controls="new-shoes-tab" aria-selected="false">Shoes &amp; Boots</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="new-access-link" data-toggle="tab" href="#new-access-tab" role="tab" aria-controls="new-access-tab" aria-selected="false">Accessories</a>
                        </li>
                    </ul>
                </div>

                <div className="tab-content">
                    <div className="tab-pane p-0 fade show active" id="new-all-tab" role="tabpanel" aria-labelledby="new-all-link">
                        <div className="products">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0888a">
                                                <img src="/img/products/0N9A0888a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Linen-blend dress</a></h3>

                                            <div className="product-price">
                                                $600

                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" ><span className="sr-only">Color name</span></a>
                                                <a href="#" ><span className="sr-only">Color name</span></a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0842a">
                                                <img src="/img/products/0N9A0842a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>

                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $700</span>
                                                <span className="old-price">Was $1500</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0963a">
                                                <img src="/img/products/0N9A0963a.jpg" alt="Product image" className="product-image" />
                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Paper bag trousers</a></h3>

                                            <div className="product-price">
                                                $600

                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active"><span className="sr-only">Color name</span></a>
                                                <a href="#" ><span className="sr-only">Color name</span></a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0423a">
                                                <img src="/img/products/0N9A0423a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Handbags</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Paper straw shopper</a></h3>

                                            <div className="product-price">
                                                $398.00

                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0643a">
                                                <img src="/img/products/0N9A0643a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Handbags</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Bucket bag</a></h3>

                                            <div className="product-price">
                                                $350

                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" ><span className="sr-only">Color name</span></a>
                                                <a href="#" ><span className="sr-only">Color name</span></a>
                                                <a href="#"><span className="sr-only">Color name</span></a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0582a">
                                                <img src="/img/products/0N9A0582a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Silk-blend kaftan</a></h3>

                                            <div className="product-price">
                                                Now $370

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0848a">
                                                <img src="/img/products/0N9A0848a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Linen-blend jumpsuit</a></h3>

                                            <div className="product-price">
                                                $595

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/DetailedProduct/0N9A0605a">
                                                <img src="/img/products/0N9A0605a.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $1200</span>
                                                <span className="old-price">Was $1400</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="tab-pane p-0 fade" id="new-cloth-tab" role="tabpanel" aria-labelledby="new-cloth-link">
                        <div className="products">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/MediaUploader/2021/9/30/Men23.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Linen-blend dress</a></h3>

                                            <div className="product-price">
                                                $600

                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" ><span className="sr-only">Color name</span></a>
                                                <a href="#" ><span className="sr-only">Color name</span></a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/img/09.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Paper bag trousers</a></h3>

                                            <div className="product-price">
                                                $600

                                            </div>

                                            <div className="product-nav product-nav-dots">
                                                <a href="#" className="active" ><span className="sr-only">Color name</span></a>
                                                <a href="#" ><span className="sr-only">Color name</span></a>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/img/07.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>

                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Silk-blend kaftan</a></h3>

                                            <div className="product-price">
                                                Now $370

                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/img/10.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Clothing</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Linen-blend jumpsuit</a></h3>

                                            <div className="product-price">
                                                $595

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="tab-pane p-0 fade" id="new-shoes-tab" role="tabpanel" aria-labelledby="new-shoes-link">
                        <div className="products">
                            <div className="row justify-content-center">
                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/MediaUploader/2021/9/30/Shoes.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $700</span>
                                                <span className="old-price">Was $1500</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/MediaUploader/2021/9/30/Shoes1.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes 1</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $120.00</span>
                                                <span className="old-price">Was $140.00</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/MediaUploader/2021/9/30/Shoes3.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes 3</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $700</span>
                                                <span className="old-price">Was $1500</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div className="col-6 col-md-4 col-lg-3">
                                    <div className="product product-2">
                                        <figure className="product-media">
                                            <a href="/products">
                                                <img src="/MediaUploader/2021/9/30/Shoes4.jpg" alt="Product image" className="product-image" />

                                            </a>

                                            <div className="product-action-vertical">
                                                <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                            </div>


                                            <div className="product-action product-action-transparent">
                                                <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                            </div>

                                        </figure>


                                        <div className="product-body">
                                            <div className="product-cat">
                                                <a href="#">Shoes</a>
                                            </div>

                                            <h3 className="product-title"><a href="/products">Shoes 4</a></h3>

                                            <div className="product-price">
                                                <span className="new-price">Now $700</span>
                                                <span className="old-price">Was $1500</span>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                        <div className="tab-pane p-0 fade" id="new-access-tab" role="tabpanel" aria-labelledby="new-access-link">
                            <div className="products">
                                <div className="row justify-content-center">
                                    <div className="col-6 col-md-4 col-lg-3">
                                        <div className="product product-2">
                                            <figure className="product-media">
                                                <a href="/products">
                                                    <img src="/img/brclt.jpg" alt="Product image" className="product-image" />

                                                </a>

                                                <div className="product-action-vertical">
                                                    <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                                </div>


                                                <div className="product-action product-action-transparent">
                                                    <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                                </div>

                                            </figure>

                                            <div className="product-body">
                                                <div className="product-cat">
                                                    <a href="#">Handbags</a>
                                                </div>

                                                <h3 className="product-title"><a href="/products">Paper straw shopper</a></h3>

                                                <div className="product-price">
                                                    $398.00

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-6 col-md-4 col-lg-3">
                                        <div className="product product-2">
                                            <figure className="product-media">
                                                <a href="/products">
                                                    <img src="/img/wallet.jpg" alt="Product image" className="product-image" />

                                                </a>

                                                <div className="product-action-vertical">
                                                    <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                                </div>

                                                <div className="product-action product-action-transparent">
                                                    <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                                </div>

                                            </figure>


                                            <div className="product-body">
                                                <div className="product-cat">
                                                    <a href="#">Handbags</a>
                                                </div>

                                                <h3 className="product-title"><a href="/products">Bucket bag</a></h3>

                                                <div className="product-price">
                                                    $350.00

                                                </div>

                                                <div className="product-nav product-nav-dots">
                                                    <a href="#" className="active" ><span className="sr-only">Color name</span></a>
                                                    <a href="#" ><span className="sr-only">Color name</span></a>
                                                    <a href="#" ><span className="sr-only">Color name</span></a>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-6 col-md-4 col-lg-3">
                                        <div className="product product-2">
                                            <figure className="product-media">
                                                <a href="/products">
                                                    <img src="/img/11.jpg" alt="Product image" className="product-image" />
                                                    <img src="/img/11-1.jpg" alt="Product image" className="product-image-hover" />
                                                </a>

                                                <div className="product-action-vertical">
                                                    <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                                </div>


                                                <div className="product-action product-action-transparent">
                                                    <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                                </div>

                                            </figure>


                                            <div className="product-body">
                                                <div className="product-cat">
                                                    <a href="#">Shoes</a>
                                                </div>

                                                <h3 className="product-title"><a href="/products">Bag 1</a></h3>

                                                <div className="product-price">
                                                    <span className="new-price">Now $700</span>
                                                    <span className="old-price">Was $1500</span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-6 col-md-4 col-lg-3">
                                        <div className="product product-2">
                                            <figure className="product-media">
                                                <a href="/products">
                                                    <img src="/img/watch-1.jpg" alt="Product image" className="product-image" />
                                                    <img src="/img/watch-2.jpg" alt="Product image" className="product-image-hover" />
                                                </a>

                                                <div className="product-action-vertical">
                                                    <a href="#" className="btn-product-icon btn-wishlist" title="Add to wishlist"><span>add to wishlist</span></a>
                                                </div>


                                                <div className="product-action product-action-transparent">
                                                    <a href="#" className="btn-product btn-cart"><span>add to cart</span></a>
                                                </div>

                                            </figure>


                                            <div className="product-body">
                                                <div className="product-cat">
                                                    <a href="#">Shoes</a>
                                                </div>

                                                <h3 className="product-title"><a href="/products">Bag 2</a></h3>

                                                <div className="product-price">
                                                    <span className="new-price">Now $120.00</span>
                                                    <span className="old-price">Was $140.00</span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>


                    <div className="more-container text-center mt-1 mb-3">
                        <a href="#" className="btn btn-outline-primary-2 btn-round btn-more">Load more</a>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="cta cta-separator mb-5">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="cta-wrapper cta-text text-center">
                                <h3 className="cta-title">Shop Social</h3>

                                <p className="cta-desc">Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. </p>


                                <div className="social-icons social-icons-colored justify-content-center">
                                    <a href="#" className="social-icon social-facebook" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                                    <a href="#" className="social-icon social-twitter" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                                    <a href="#" className="social-icon social-instagram" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                                    <a href="#" className="social-icon social-youtube" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                                    <a href="#" className="social-icon social-pinterest" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                                </div>

                            </div>

                        </div>


                        <div className="col-lg-6">
                            <div className="cta-wrapper text-center">
                                <h3 className="cta-title">Get the Latest Deals</h3>

                                <p className="cta-desc">
                                    and
                                    <br />
                                    receive <span className="text-primary">$20 coupon</span> for first shopping
                                </p>


                                <form action="#">
                                    <div className="input-group">
                                        <input type="email" className="form-control" placeholder="Enter your Email Address" aria-label="Email Adress" required="" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary btn-rounded" type="submit"><i className="icon-long-arrow-right"></i></button>
                                        </div>

                                    </div>

                                </form>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

            <div className="bg-lighter pt-7 pb-4" >
                <div className="container">
                    <div className="instagram-feed-container">
                        <div className="row">
                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/insta1.png" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>387</a>
                                        <a href="#"><i className="icon-comments"></i>45</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/insta2.png" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>691</a>
                                        <a href="#"><i className="icon-comments"></i>87</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col feed-col-title">
                                <div className="instagram-feed-title">
                                    <img src="/img/logo-footer1.png" />

                                    <p >
                                        @MR1_Brand
                                        <br />
                                        on instagram
                                    </p>
                                    <a href="#">FOLLOW</a>
                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/insta3.png" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>321</a>
                                        <a href="#"><i className="icon-comments"></i>54</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/insta4.png" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>44</a>
                                        <a href="#"><i className="icon-comments"></i>55</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/5.jpg" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>128</a>
                                        <a href="#"><i className="icon-comments"></i>99</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/6.jpg" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>433</a>
                                        <a href="#"><i className="icon-comments"></i>25</a>
                                    </div>

                                </div>

                            </div>


                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/7.jpg" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>588</a>
                                        <a href="#"><i className="icon-comments"></i>44</a>
                                    </div>

                                </div>

                            </div>

                            <div className="feed-col">
                                <div className="instagram-feed">
                                    <img src="/img/8.jpg" alt="img" />

                                    <div className="instagram-feed-content">
                                        <a href="#"><i className="icon-heart-o"></i>87</a>
                                        <a href="#"><i className="icon-comments"></i>23</a>
                                    </div>


                                </div>


                                <div className="feed-col">
                                    <div className="instagram-feed">
                                        <img src="/img/9.jpg" alt="img" />

                                        <div className="instagram-feed-content">
                                            <a href="#"><i className="icon-heart-o"></i>87</a>
                                            <a href="#"><i className="icon-comments"></i>23</a>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div className="row justify-content-center">
                            <div className="col-sm-6 col-lg-4">
                                <div className="icon-box icon-box-side">
                                    <span className="icon-box-icon text-dark">
                                        <i className="icon-rocket"></i>
                                    </span>

                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Payment &amp; Delivery</h3>

                                        <p>Free shipping for orders over $50</p>
                                    </div>

                                </div>

                            </div>


                            <div className="col-sm-6 col-lg-4">
                                <div className="icon-box icon-box-side">
                                    <span className="icon-box-icon text-dark">
                                        <i className="icon-refresh"></i>
                                    </span>

                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Return &amp; Refund</h3>

                                        <p>Free 100% money back guarantee</p>
                                    </div>

                                </div>

                            </div>


                            <div className="col-sm-6 col-lg-4">
                                <div className="icon-box icon-box-side">
                                    <span className="icon-box-icon text-dark">
                                        <i className="icon-life-ring"></i>
                                    </span>

                                    <div className="icon-box-content">
                                        <h3 className="icon-box-title">Quality Support</h3>

                                        <p>Alway online feedback 24/7</p>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>

    )
}
export default Home;

