import React, { useState } from 'react'
import { variables } from '../../Variables/variables';
import { useNavigate } from 'react-router-dom';
const Login = () => {
    const navigate=useNavigate()
    const[email,setEmail]=useState();
    const[password,setPassword]=useState()
    function handleLogin(){
        const login = async () => {
            let response = await fetch(variables.API_URL + "CustomerLogin", {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
               
                Email: email,
                Password:password
               
              }),
            });
            let result = await response.json();
			if(result=="Success"){
				navigate("/Home")
			}
            };
            setTimeout(() => {
              (async () => await login())();
            }, 4);
    }


  return (
    <div>

<nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
                <div class="container">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/Home">Home</a></li>
                        <li class="breadcrumb-item active" aria-current="page">Login</li>
                    </ol>
                </div>
            </nav>

            <div class="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17" style={{backgroundImage:" url(/img/login-bg.jpg)"}}>
            	<div class="container">
            		<div class="form-box">
            			<div class="form-tab">
	            			<ul class="nav nav-pills nav-fill" role="tablist">
							    <li class="nav-item">
							      
                                <a class="nav-link active" id="register-tab-2" data-toggle="tab" href="#register-2" role="tab" aria-controls="register-2" aria-selected="true">LOG IN</a>
                                
							    </li>
							
							</ul>
							<div class="tab-content">
							    <div class="tab-pane fade show active" id="register-2" role="tabpanel" aria-labelledby="register-tab-2">
							    	<form action="#">
							    		<div class="form-group">
							    			<label for="register-email-2">Your email address *</label>
							    			<input type="email" class="form-control" id="register-email-2" name="register-email" required="" value={email} onChange={(e)=>setEmail(e.target.value)}/>
							    		</div>

							    		<div class="form-group">
							    			<label for="register-password-2">Password *</label>
							    			<input type="password" class="form-control" id="register-password-2" name="register-password" required="" value={password} onChange={(e)=>setPassword(e.target.value)}  />
							    		</div>

							    		<div class="form-footer">
							    			<button type="submit" class="btn btn-outline-primary-2" onClick={handleLogin} >
			                					<span> LOG IN</span>
			            						<i class="icon-long-arrow-right"></i>
			                				</button>

			                				<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input" id="register-policy-2" required=""/>
												<label class="custom-control-label" for="register-policy-2">I agree to the <a href="#">privacy policy</a> *</label>
											</div>
							    		</div>
							    	</form>
							    	<div class="form-choice">
								    	<p class="text-center">or sign in with</p>
								    	<div class="row">
								    		<div class="col-sm-6">
								    			<a href="#" class="btn btn-login btn-g">
								    				<i class="icon-google"></i>
								    				Login With Google
								    			</a>
								    		</div>
								    		<div class="col-sm-6">
								    			<a href="#" class="btn btn-login  btn-f">
								    				<i class="icon-facebook-f"></i>
								    				Login With Facebook
								    			</a>
								    		</div>
								    	</div>
							    	</div>
							    </div>
							</div>
						</div>
            		</div>
            	</div>
            </div>


    </div>
  )
}

export default Login