import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
const DetailedProduct = () => {
    const{ ImageName }=useParams();
    const[counter,setCounter]=useState(0)
function handlePlus(){
setCounter(...counter+1)
}
  return (
    <div>

<div class="page-header text-center" style={{backgroundImage: "url(/img/page-header-bg.jpg)"}}>
    <div class="container">
        <h1 class="page-title">
            </h1>
    </div>
 
</div>
  <nav aria-label="breadcrumb" class="breadcrumb-nav border-0 mb-0">
        <div class="container d-flex align-items-center">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/">Home</a></li>
                <li class="breadcrumb-item"><a href="/Category/Men/SubCategory/ShopAll">Products</a></li>
            </ol>
         
        </div>
   
    </nav>
<div class="page-content">
        <div class="container">
            <div class="product-details-top mb-2">
                {/* Tops */}
            {ImageName==="0N9A0423a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0423a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0431a.jpg"/>
                                    </a>

                                    <a class="product-gallery-item" href="#">
                                    <img src="/img/products/0N9A0473a.jpg"/>
                                    </a>

                                    <a class="product-gallery-item" href="#">
                                    
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           250  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control"  style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" ><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner"  onClick={handlePlus}><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
              {ImageName==="0N9A0527a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0527a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0502a.jpg"/>
                                    </a>
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           300  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
              ):null}

            {ImageName==="0N9A0643a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0643a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0626a.jpg"/>
                                    </a>

                                    <a class="product-gallery-item" href="#">
                                    <img src="/img/products/0N9A0629a.jpg"/>
                                    </a>

                                    <a class="product-gallery-item" href="#">
                                    <img src="/img/products/0N9A0616a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                    <img src="/img/products/0N9A0642a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           500  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

            {ImageName==="0N9A0842a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0842a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0845a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           400  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

            {ImageName==="0N9A1013a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A1013a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                   
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           600  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
            {/* Hoodies */}
              {ImageName==="0N9A0751a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0751a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0755.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           300  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

                {ImageName==="0N9A0653a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0653a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0663a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           270  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

        
               {ImageName==="0N9A0587a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0587a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0590a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           400  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
            {ImageName==="0N9A0605a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0605a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                               
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           250  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

            {/* Zip Jackets */}
              {ImageName==="0N9A0582a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0582a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0577a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A1032a-Recovered.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0972a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           200  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

            {/* Tracksuits */}
            {ImageName==="0N9A0704a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0704a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0727a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0728a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0720a.jpg"/>
                                    </a>

                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           500  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

            {ImageName==="0N9A0888a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0888a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0894a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0898a.jpg"/>
                                    </a>
                                  
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           700  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
               {ImageName==="0N9A1019a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A1019a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                              
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           650  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
              {ImageName==="0N9A0848a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0848a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0855a.jpg"/>
                                    </a>
                                    <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0856a.jpg"/>
                                    </a>
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           800  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}

                {/* Trousers */}
                {ImageName==="0N9A0963a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A0963a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A0972a.jpg"/>
                                    </a>
                                  
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           300  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
              {ImageName==="0N9A1032a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A1032a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A1047a.jpg"/>
                                    </a>
                                  
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           250  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
            {ImageName==="0N9A1053"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A1053.a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A1064a.jpg"/>
                                    </a>
                                  
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           350  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
    {ImageName==="0N9A1059a"?(
                <div class="row">
                    <div class="col-md-6">
                        <div class="product-gallery product-gallery-vertical">
                      
                            <div class="row">
                                <figure class="product-main-image">
                                   <img src="/img/products/0N9A1059a.jpg"/>
                                
                                </figure>
                                <div id="product-zoom-gallery" class="product-image-gallery">
                                <a class="product-gallery-item" href="#">
                                      <img src="/img/products/0N9A1064a.jpg"/>
                                    </a>
                                  
                                </div>
                              
                            </div>
                      
                           
                        </div>
                     
                    </div>
                   

                    <div class="col-md-6">
                        <div class="product-details ">
                            <h1 class="product-title">
                           Product Name
                            </h1>
                            <div className="product-price">
                                
                           150  $
                             
                            </div>
                           

                            <div class="product-content">
                                <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                        

                          
                        

                            <div class="details-filter-row details-row-size">
                                <label for="size">Size:</label>
                                <div class="select-custom">
                                    <select name="size" id="size" class="form-control">
                                        <option value="#" selected="selected">One Size</option>
                                        <option value="s">Small</option>
                                        <option value="m">Medium</option>
                                        <option value="l">Large</option>
                                        <option value="xl">Extra Large</option>
                                    </select>
                                </div>
                          
                                <a href="#" class="size-guide"><i class="icon-th-list"></i>size guide</a>
                            </div>
                      

                            <div class="product-details-action">
                                <div class="details-action-col">
                                    <div class="product-details-quantity">
                                        <input type="number" value={counter}  id="qty" class="form-control" min="1" max="10" step="1" data-decimals="0" required="" style={{display: "none"}}/>
                                        <div class="input-group input-spinner">
                                            <div class="input-group-prepend">
                                                <button style={{minWidth: "26px"}} class="btn btn-decrement btn-spinner" type="button"><i class="icon-minus"></i></button>
                                            </div>
                                            <input type="text" style={{textAlign: "center"}} class="form-control " required="" placeholder=""/><div class="input-group-append">
                                                <button style={{minWidth: "26px"}} class="btn btn-increment btn-spinner" type="button"><i class="icon-plus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                  

                                    <a href="#" class="btn-product btn-cart"><span>add to cart</span></a>
                                </div>
                           

                                <div class="details-action-wrapper">
                                    <a href="#" class="btn-product btn-wishlist" title="Wishlist"><span>Add to Wishlist</span></a>
                                </div>
                               
                            </div>
                        

                            <div class="product-details-footer">
                                <div class="product-cat">
                                    <span>Category:</span>
                                
                                </div>
                              

                                <div class="social-icons social-icons-sm">
                                    <span class="social-label">Share:</span>
                                    <a href="#" class="social-icon" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                                    <a href="#" class="social-icon" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                                    <a href="#" class="social-icon" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                                    <a href="#" class="social-icon" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                                </div>
                            </div>
                          
                        </div>
                     
                    </div>
                   
                </div>
            ):null}
            </div>
         

            <div class="product-details-tab">
                <ul class="nav nav-pills justify-content-center" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="product-desc-link" data-toggle="tab" href="#product-desc-tab" role="tab" aria-controls="product-desc-tab" aria-selected="true">Description</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="product-info-link" data-toggle="tab" href="#product-info-tab" role="tab" aria-controls="product-info-tab" aria-selected="false">Additional information</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="product-shipping-link" data-toggle="tab" href="#product-shipping-tab" role="tab" aria-controls="product-shipping-tab" aria-selected="false">Shipping &amp; Returns</a>
                    </li>
                </ul>
                <div class="tab-content">
                    <div class="tab-pane fade show active" id="product-desc-tab" role="tabpanel" aria-labelledby="product-desc-link">
                        <div class="product-desc-content">
                            <h3>Product Information</h3>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. </p>
                            <ul>
                                <li>Nunc nec porttitor turpis. In eu risus enim. In vitae mollis elit. </li>
                                <li>Vivamus finibus vel mauris ut vehicula.</li>
                                <li>Nullam a magna porttitor, dictum risus nec, faucibus sapien.</li>
                            </ul>

                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. Aenean dignissim pellentesque felis. Phasellus ultrices nulla quis nibh. Quisque a lectus. Donec consectetuer ligula vulputate sem tristique cursus. </p>
                        </div>
                      
                    </div>
                 
                    <div class="tab-pane fade" id="product-info-tab" role="tabpanel" aria-labelledby="product-info-link">
                        <div class="product-desc-content">
                            <h3>Information</h3>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna viverra non, semper suscipit, posuere a, pede. Donec nec justo eget felis facilisis fermentum. Aliquam porttitor mauris sit amet orci. </p>

                            <h3>Fabric &amp; care</h3>
                            <ul>
                                <li>Faux suede fabric</li>
                                <li>Gold tone metal hoop handles.</li>
                                <li>RI branding</li>
                                <li>Snake print trim interior </li>
                                <li>Adjustable cross body strap</li>
                                <li>Height: 31cm; Width: 32cm; Depth: 12cm; Handle Drop: 61cm</li>
                            </ul>

                            <h3>Size</h3>
                            <p>one size</p>
                        </div>
                        
                    </div>
                 
                    <div class="tab-pane fade" id="product-shipping-tab" role="tabpanel" aria-labelledby="product-shipping-link">
                        <div class="product-desc-content">
                            <h3>Delivery &amp; returns</h3>
                            <p>
                                We deliver to over 100 countries around the world. For full details of the delivery options we offer, please view our <a href="#">Delivery information</a><br/>
                                We hope you’ll love every purchase, but if you ever need to return an item you can do so within a month of receipt. For full details of how to make a return, please view our <a href="#">Returns information</a>
                            </p>
                        </div>
                      
                    </div>
                </div>
               
            </div>
           

            <h2 class="title text-center mb-4">You May Also Like</h2>
       
            <div class="owl-carousel owl-simple carousel-equal-height carousel-with-shadow owl-loaded owl-drag" data-toggle="owl" data-owl-options="{
                            &quot;nav&quot;: false, 
                            &quot;dots&quot;: true,
                            &quot;margin&quot;: 20,
                            &quot;loop&quot;: false,
                            &quot;responsive&quot;: {
                                &quot;0&quot;: {
                                    &quot;items&quot;:1
                                },
                                &quot;480&quot;: {
                                    &quot;items&quot;:2
                                },
                                &quot;768&quot;: {
                                    &quot;items&quot;:3
                                },
                                &quot;992&quot;: {
                                    &quot;items&quot;:4
                                },
                                &quot;1200&quot;: {
                                    &quot;items&quot;:4,
                                    &quot;nav&quot;: true,
                                    &quot;dots&quot;: false
                                }
                            }
                        }">

                {/* <asp:Repeater ID="rptGetRandomProducts" runat="server">
                    <ItemTemplate>
                        <div class="product product-7 text-center">
                            <figure class="product-media">
                                <a href="/">
                                    <img src="<%#Eval("Product")%>" alt="<%#Eval("ProductName")%>" class="product-image">
                                </a>
                                <div class="product-action-vertical">
                                    <asp:LinkButton ID="btnAddToWishlist" CssClass="btn-product-icon btn-wishlist btn-expandable" CommandArgument='<%#Eval("ProductID") %>' OnClick="AddToWishlist_Click" runat="server"><span>add to wishlist</span></asp:LinkButton>
                                    <%--<a href="#" class="btn-product-icon btn-wishlist btn-expandable"><span>add to wishlist</span></a>--%>
                                    <a href="/" class="btn-product-icon btn-quickview" title="View"><span>View</span></a>
                                </div>
                                <div class="product-action">
                                    <asp:LinkButton ID="btnAddToCart" CssClass="btn-product btn-cart" CommandArgument='<%#Eval("ProductID") %>' OnClick="AddToCart_click"  runat="server"><span>add to cart</span></asp:LinkButton>
                                    <%--<a href="#" class="btn-product btn-cart"><span>add to cart</span></a>--%>
                                </div>
                            </figure>
                            <div class="product-body">
                                <div class="product-cat">
                                    <a href="#">Women</a>
                                </div>
                                <h3 class="product-title">
                                    <a href="/">
                                        <%#Eval("ProductName")%>
                                    </a>
                                </h3>
                                <div class="product-price">
                                    $ <%#Eval("RegularPrice")%>
                                </div>
                            </div>
                        </div>
                    </ItemTemplate>
                </asp:Repeater> */}
            </div>
        </div>
    </div>

    </div>
  )
}

export default DetailedProduct