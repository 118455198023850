import AppRoutes from './Variables/AppRoutes';
function App() {
  return (
    <>
      <header className="header header-5">
        <div className="sticky-wrapper">
          <div className="header-middle sticky-header">
            <div className="container">
              <div className="header-left">
                <button className="mobile-menu-toggler">
                  <span className="sr-only">Toggle mobile menu</span>
                  <i className="icon-bars"></i>
                </button>

                <a href="/" className="logo">
                  <img src="/img/logo-footer1.png" alt="MR1 Logo" width="105" height="25" />
                </a>

                <nav className="main-nav">
                  <ul className="menu sf-arrows sf-js-enabled">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li className="">
                      <a href="/Category/Men/SubCategory/ShopAll" className="sf-with-ul">MR1 MEN</a>
                      <ul>
                        <li><a href="/Category/Men/SubCategory/ShopAll">Shop All</a></li>
                        <li><a href="/Category/Men/SubCategory/Tops">Tops</a></li>
                        <li><a href="/Category/Men/SubCategory/Hoodies">Hoodies</a></li>
                        <li><a href="/Category/Men/SubCategory/ZipJackets">Zip Jackets</a></li>
                        <li><a href="/Category/Men/SubCategory/Tracksuits">Tracksuits</a></li>
                        <li><a href="/Category/Men/SubCategory/Trousers">Trousers</a></li>
                      </ul>
                    </li>
                    <li className="">
                      <a href="/Women" className="sf-with-ul">MR1 WOMEN</a>
                      <ul>
                      <li><a href="/Category/Women/SubCategory/ShopAll">Shop All</a></li>
                        <li><a href="/Category/Women/SubCategory/Tops">Tops</a></li>
                        <li><a href="/Category/Women/SubCategory/OverAll">Over Alls</a></li>
                        
                        <li><a href="/Category/Women/SubCategory/Dresses">Dresses</a></li>
                        <li><a href="/Category/Women/SubCategory/Shoes">Shoes</a></li>
                        <li><a href="/Category/Women/SubCategory/Bags">Bags</a></li>
                      </ul>
                    </li>
                    <li className="">
                      <a href="/Junior" className="sf-with-ul">MR1 Junior</a>
                      <ul>
                        <li><a href="/ShopAll">Shop All</a></li>
                      </ul>
                    </li>
                    <li className="">
                      <a href="/NewsEvents">News &amp; Events</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="header-right">
                <div className="header-search header-search-extended header-search-visible">
                  <a href="#" className="search-toggle" role="button"><i className="icon-search"></i></a>
                  <div action="#" method="get">
                    <div className="header-search-wrapper">
                      <label htmlFor="q" className="sr-only">Search</label>
                      <input type="search" className="form-control" name="q" id="q" placeholder="Search product ..." required="" />
                      <button className="btn btn-primary" type="submit"><i className="icon-search"></i></button>
                    </div>
                  </div>
                </div>
                <a href="/wishlist" className="wishlist-link">
                  <i className="icon-heart-o"></i>
                </a>

                <div className="dropdown cart-dropdown">
                  <a href="#" className="dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-display="static">
                    <i className="icon-shopping-cart"></i>
                    <span className="cart-count">2</span>
                  </a>

                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-cart-products">
                      <div className="product">
                        <div className="product-cart-details">
                          <h4 className="product-title">
                            <a href="/products">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                          </h4>

                          <span className="cart-product-info">
                            <span className="cart-product-qty">1</span>
                            x $250.00
                          </span>
                        </div>
                        <figure className="product-image-container">
                        <a href="/DetailedProduct/0N9A1032a" className='product-image'>
															<img src="/img/products/0N9A1032a.jpg" alt="Product image"/>
														</a>
                        </figure>
                        <a href="#" className="btn-remove" title="Remove Product"><i className="icon-close"></i></a>
                      </div>
                      <div className="product">
                        <div className="product-cart-details">
                          <h4 className="product-title">
                            <a href="/products">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                          </h4>

                          <span className="cart-product-info">
                            <span className="cart-product-qty">1</span>
                            x $250.00
                          </span>
                        </div>
                        <figure className="product-image-container">
                        <a href="/DetailedProduct/0N9A0423a" className='product-image'>
															<img src="/img/products/0N9A0423a.jpg" alt="Product image"/>
														</a>
                        </figure>
                        <a href="#" className="btn-remove" title="Remove Product"><i className="icon-close"></i></a>
                      </div>
                    </div>
                    <div className="dropdown-cart-total">
                      <span>Total</span>

                      <span className="cart-total-price">$500.00</span>
                    </div>
                    <div className="dropdown-cart-action">
                      <a href="/Cart" className="btn btn-primary">View Cart</a>
                      <a href="/Checkout" className="btn btn-outline-primary-2"><span>Checkout</span><i className="icon-long-arrow-right"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <AppRoutes />
      <footer class="footer footer-2">
            <div class="footer-middle border-0">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-lg-6">
                            <div class="widget widget-about">
                                <img src="/img/logo-footer1.png" class="footer-logo" alt="Footer Logo" width="105" height="25"/>
                                <p>Praesent dapibus, neque id cursus ucibus, tortor neque egestas augue, eu vulputate magna eros eu erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis, accumsan porttitor, facilisis luctus, metus. </p>
                                <div class="widget-about-info">
                                    <div class="row">
                                        <div class="col-sm-6 col-md-4">
                                            <span class="widget-about-title">Got Question? Call us 24/7</span>
                                            <a href="tel:123456789">+971 (123) 123 1234</a>
                                        </div>
                                        <div class="col-sm-6 col-md-8">
                                            <span class="widget-about-title">Payment Method</span>
                                            <figure class="footer-payments">
                                                <img src="/img/payments.png" alt="Payment methods" width="272" height="20"/>
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-2">
                            <div class="widget">
                                <h4 class="widget-title">Information</h4>
                                <ul class="widget-list">
                                    <li><a href="/TheBrand/7">About MR1</a></li>
                                    <li><a href="#">How to shop on MR1</a></li>
                                    <li><a href="/faq/17">FAQ</a></li>
                                    <li><a href="/contact/10">Contact us</a></li>
                                   
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-2">
                            <div class="widget">
                                <h4 class="widget-title">Customer Service</h4>
                                <ul class="widget-list">
                                    <li><a href="/payment-methods/18">Payment Methods</a></li>
                                    <li><a href="#">Money-back guarantee!</a></li>
                                    <li><a href="/returns/19">Returns</a></li>
                                    <li><a href="/shipping/20">Shipping</a></li>
                                    <li><a href="#">Terms and conditions</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-lg-2">
                            <div class="widget">
                                <h4 class="widget-title">My Account</h4>
                                <ul class="widget-list">
                                    <li><a href="Login">LogIn</a></li>
                                    <li><a href="Register"> SignUp</a></li>
                                    <li><a href="/cart">View Cart</a></li>
                                    <li><a href="/wishlist">My Wishlist</a></li>
                                    <li><a href="/track">Track My Order</a></li>
                                    <li><a href="/help">Help</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="container">
                    <p class="footer-copyright">Copyright © 2022 MR1 Store. All Rights Reserved.</p>
                    <ul class="footer-menu">
                        <li><a href="#">Terms Of Use</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                    <div class="social-icons social-icons-color">
                        <span class="social-label">Social Media</span>
                        <a href="#" class="social-icon social-facebook" title="Facebook" target="_blank"><i class="icon-facebook-f"></i></a>
                        <a href="#" class="social-icon social-twitter" title="Twitter" target="_blank"><i class="icon-twitter"></i></a>
                        <a href="#" class="social-icon social-instagram" title="Instagram" target="_blank"><i class="icon-instagram"></i></a>
                        <a href="#" class="social-icon social-youtube" title="Youtube" target="_blank"><i class="icon-youtube"></i></a>
                        <a href="#" class="social-icon social-pinterest" title="Pinterest" target="_blank"><i class="icon-pinterest"></i></a>
                    </div>
                </div>
            </div>
        </footer>
    </>
  );
}

export default App;
