import React from "react";
import Home from '../Home'
import WishList from '../Pages/WishList'
import { Route, Routes } from 'react-router-dom';
import ListProducts from "../Pages/Products/ListProducts";
import Register from "../Pages/Customer/Register";
import Login from "../Pages/Customer/Login";
import Cart from "../Pages/Cart";
import Checkout from "../Pages/Checkout";
import DetailedProduct from "../Pages/Products/DetailedProduct";
const AppRoutes = () => {
    return (
        <Routes>
            {/* <Route path="*" element={<NotFound />} /> */}
            <Route path="/" element={<Home />} />
            <Route path="WishList" element={<WishList />} />
            {/* <Route path="/Category/:CategoryName" element={<ListProducts />} /> */}
            <Route exact path="/Category/:CategoryName/SubCategory/:SubCategoryName" element={<ListProducts />}/>
            <Route path="Register" element={<Register/>}/>
            <Route path="Login" element={<Login/>}/>
            <Route path="/Cart" element={<Cart/>}/>
            <Route path="/Checkout" element={<Checkout/>}/>
            <Route path="/DetailedProduct/:ImageName" element={<DetailedProduct/>}/>
        </Routes>
    )
}
export default AppRoutes;