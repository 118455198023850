import { useParams } from "react-router-dom";

export const ListProducts = () => {
  const { CategoryName, SubCategoryName } = useParams();
  return (
    <>
      <div
        className="page-header text-center"
        style={{ backgroundImage: "url('/assets/images/page-header-bg.jpg')" }}
      >
        <div className="container">
          <h1 className="page-title">{SubCategoryName}</h1>
        </div>
      </div>
      <nav aria-label="breadcrumb" className="breadcrumb-nav mb-2">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="#">MR1 Collection</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {/* <asp:Literal ID="litMenuTitle" runat="server"></asp:Literal> */}
            </li>
          </ol>
        </div>
      </nav>
      <div className="page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="toolbox">
                <div className="toolbox-left">
                  <div className="toolbox-info">
                    Showing <span>9 of 56</span> Products
                  </div>
                </div>
                <div className="toolbox-right">
                  <div className="toolbox-sort">
                    <label htmlFor="sortby">Sort by:</label>
                    <div className="select-custom">
                      <select
                        name="sortby"
                        id="sortby"
                        className="form-control"
                      >
                        <option value="popularity" selected="selected">
                          Most Popular
                        </option>
                        <option value="rating">Most Rated</option>
                        <option value="date">Date</option>
                      </select>
                    </div>
                  </div>
                  <div className="toolbox-layout">
                    <a href="#" className="btn-layout active">
                      <svg width="16" height="10">
                        <rect x="0" y="0" width="4" height="4"></rect>
                        <rect x="6" y="0" width="10" height="4"></rect>
                        <rect x="0" y="6" width="4" height="4"></rect>
                        <rect x="6" y="6" width="10" height="4"></rect>
                      </svg>
                    </a>

                    <a href="#" className="btn-layout">
                      <svg width="10" height="10">
                        <rect x="0" y="0" width="4" height="4"></rect>
                        <rect x="6" y="0" width="4" height="4"></rect>
                        <rect x="0" y="6" width="4" height="4"></rect>
                        <rect x="6" y="6" width="4" height="4"></rect>
                      </svg>
                    </a>

                    <a href="#" className="btn-layout">
                      <svg width="16" height="10">
                        <rect x="0" y="0" width="4" height="4"></rect>
                        <rect x="6" y="0" width="4" height="4"></rect>
                        <rect x="12" y="0" width="4" height="4"></rect>
                        <rect x="0" y="6" width="4" height="4"></rect>
                        <rect x="6" y="6" width="4" height="4"></rect>
                        <rect x="12" y="6" width="4" height="4"></rect>
                      </svg>
                    </a>

                    <a href="#" className="btn-layout">
                      <svg width="22" height="10">
                        <rect x="0" y="0" width="4" height="4"></rect>
                        <rect x="6" y="0" width="4" height="4"></rect>
                        <rect x="12" y="0" width="4" height="4"></rect>
                        <rect x="18" y="0" width="4" height="4"></rect>
                        <rect x="0" y="6" width="4" height="4"></rect>
                        <rect x="6" y="6" width="4" height="4"></rect>
                        <rect x="12" y="6" width="4" height="4"></rect>
                        <rect x="18" y="6" width="4" height="4"></rect>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div className="products mb-3">
                <div className="product product-list">
                  {CategoryName === "Men" && SubCategoryName === "Tops" ? (
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0423a">
                              <img src="/img/products/0N9A0423a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="/DetailedProduct">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                              <a href="/DetailedProduct/0N9A0423a">
                                <img src="/img/products/0N9A0431a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0423a">
                                <img src="/img/products/0N9A0473a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0527a">
                              <img src="/img/products/0N9A0527a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="/DetailedProduct">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                              <a href="/DetailedProduct/0N9A0527a">
                                <img src="/img/products/0N9A0502a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0643a">
                              <img src="/img/products/0N9A0643a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 500</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0626a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0629a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0616a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0642a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0842a">
                              <img src="/img/products/0N9A0842a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 400</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0842a">
                                <img src="/img/products/0N9A0845a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1013a">
                              <img src="/img/products/0N9A1013a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 600</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {CategoryName === "Men" && SubCategoryName === "Hoodies" ? (
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0751a">
                              <img src="/img/products/0N9A0751a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0751a">
                                <img src="/img/products/0N9A0755.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0653a">
                              <img src="/img/products/0N9A0653a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 270</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0653a">
                                <img src="/img/products/0N9A0663a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0587a">
                              <img src="/img/products/0N9A0587a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 400</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0587a">
                                <img src="/img/products/0N9A0590a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0605a">
                              <img src="/img/products/0N9A0605a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {CategoryName === "Men" &&
                  SubCategoryName === "ZipJackets" ? (
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0582a">
                              <img src="/img/products/0N9A0582a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 200</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A0577a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A1032a-Recovered.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A0972a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {CategoryName === "Men" &&
                  SubCategoryName === "Tracksuits" ? (
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0704a">
                              <img src="/img/products/0N9A0704a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 500</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0727a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0728a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0720a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0888a">
                              <img src="/img/products/0N9A0888a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 700</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0888a">
                                <img src="/img/products/0N9A0894a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0888a">
                                <img src="/img/products/0N9A0898a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1019a">
                              <img src="/img/products/0N9A1019a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 650</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0848a">
                              <img src="/img/products/0N9A0848a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 800</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0848a">
                                <img src="/img/products/0N9A0855a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0848a">
                                <img src="/img/products/0N9A0856a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {CategoryName === "Men" && SubCategoryName === "Trousers" ? (
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0963a">
                              <img src="/img/products/0N9A0963a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0963a">
                                <img src="/img/products/0N9A0972a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1032a">
                              <img src="/img/products/0N9A1032a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A1032a">
                                <img src="/img/products/0N9A1047a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1053">
                              <img src="/img/products/0N9A1053.a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 350</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A1053">
                                <img src="/img/products/0N9A1064a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/Detailedproduct/0N9A1059a">
                              <img src="/img/products/0N9A1059a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 150</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                               <a href="/Detailedproduct/0N9A1059a">
                                <img src="/img/products/0N9A1064a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {CategoryName === "Men" && SubCategoryName === "ShopAll" ? (
                    <div>
                      {/* Tops */}
                      <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0423a">
                              <img src="/img/products/0N9A0423a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="/DetailedProduct">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                              <a href="/DetailedProduct/0N9A0423a">
                                <img src="/img/products/0N9A0431a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0423a">
                                <img src="/img/products/0N9A0473a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0527a">
                              <img src="/img/products/0N9A0527a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="/DetailedProduct">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                              <a href="/DetailedProduct/0N9A0527a">
                                <img src="/img/products/0N9A0502a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0643a">
                              <img src="/img/products/0N9A0643a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 500</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0626a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0629a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0616a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0643a">
                                <img src="/img/products/0N9A0642a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0842a">
                              <img src="/img/products/0N9A0842a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 400</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0842a">
                                <img src="/img/products/0N9A0845a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1013a">
                              <img src="/img/products/0N9A1013a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 600</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                      {/* Hoodies */}
                      <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0751a">
                              <img src="/img/products/0N9A0751a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0751a">
                                <img src="/img/products/0N9A0755.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0653a">
                              <img src="/img/products/0N9A0653a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 270</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0653a">
                                <img src="/img/products/0N9A0663a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0587a">
                              <img src="/img/products/0N9A0587a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 400</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0587a">
                                <img src="/img/products/0N9A0590a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0605a">
                              <img src="/img/products/0N9A0605a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* zip jackets */}

                   <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0582a">
                              <img src="/img/products/0N9A0582a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 200</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A0577a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A1032a-Recovered.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0582a">
                                <img src="/img/products/0N9A0972a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Tracksuits */}
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0704a">
                              <img src="/img/products/0N9A0704a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 500</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0727a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0728a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0704a">
                                <img src="/img/products/0N9A0720a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0888a">
                              <img src="/img/products/0N9A0888a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 700</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0888a">
                                <img src="/img/products/0N9A0894a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0888a">
                                <img src="/img/products/0N9A0898a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1019a">
                              <img src="/img/products/0N9A1019a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 650</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs"></div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0848a">
                              <img src="/img/products/0N9A0848a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 800</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0848a">
                                <img src="/img/products/0N9A0855a.jpg" />
                              </a>
                              <a href="/DetailedProduct/0N9A0848a">
                                <img src="/img/products/0N9A0856a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Trousers */}
                    <div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A0963a">
                              <img src="/img/products/0N9A0963a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 300</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A0963a">
                                <img src="/img/products/0N9A0972a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1032a">
                              <img src="/img/products/0N9A1032a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 250</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A1032a">
                                <img src="/img/products/0N9A1047a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/DetailedProduct/0N9A1053">
                              <img src="/img/products/0N9A1053.a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 350</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                            <a href="/DetailedProduct/0N9A1053">
                                <img src="/img/products/0N9A1064a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-6 col-lg-3">
                          <figure className="product-media">
                            <a href="/Detailedproduct/0N9A1059a">
                              <img src="/img/products/0N9A1059a.jpg" />
                            </a>
                          </figure>
                        </div>
                        <div className="col-6 col-lg-3 order-lg-last">
                          <div className="product-list-action">
                            <h4>$ 150</h4>
                            <div className="product-action">
                              View
                              <a
                                href="#"
                                className="btn-product"
                                title="Compare"
                              >
                                <span>compare</span>
                              </a>
                            </div>
                            <a href="#" className="btn-product btn-cart">
                              <span>add to cart</span>
                            </a>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="product-body product-action-inner">
                            <a
                              href="#"
                              className="btn-product btn-wishlist"
                              title="Add to wishlist"
                            >
                              <span>add to wishlist</span>
                            </a>
                            <h3 className="product-title">
                              <a href="#">Product Name</a>
                            </h3>
                            <div className="product-content">
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat.
                              </p>
                            </div>
                            <div className="product-nav product-nav-thumbs">
                               <a href="/Detailedproduct/0N9A1059a">
                                <img src="/img/products/0N9A1064a.jpg" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    </div>
                  ) : null}
                </div>
              </div>
              <nav aria-label="Page navigation">
                <ul className="pagination">
                  <li className="page-item disabled">
                    <a
                      className="page-link page-link-prev"
                      href="#"
                      aria-label="Previous"
                      tabindex="-1"
                      aria-disabled="true"
                    >
                      <span aria-hidden="true">
                        <i className="icon-long-arrow-left"></i>
                      </span>
                      Prev
                    </a>
                  </li>
                  <li className="page-item active" aria-current="page">
                    <a className="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li className="page-item-total">of 6</li>
                  <li className="page-item">
                    <a
                      className="page-link page-link-next"
                      href="#"
                      aria-label="Next"
                    >
                      Next{" "}
                      <span aria-hidden="true">
                        <i className="icon-long-arrow-right"></i>
                      </span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <aside className="col-lg-3 order-lg-first">
              <div className="sidebar sidebar-shop">
                <div className="widget widget-clean">
                  <label>Filters:</label>
                  <a href="#" className="sidebar-filter-clear">
                    Clean All
                  </a>
                </div>
                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <a
                      data-toggle="collapse"
                      href="#widget-1"
                      role="button"
                      aria-expanded="true"
                      aria-controls="widget-1"
                    >
                      Category
                    </a>
                  </h3>
                  <div className="collapse show" id="widget-1">
                    <div className="widget-body">
                      <div className="filter-items filter-items-count">
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                            />
                            <label className="custom-control-label">
                              Category Name
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <a
                      data-toggle="collapse"
                      href="#widget-2"
                      role="button"
                      aria-expanded="true"
                      aria-controls="widget-2"
                    >
                      Size
                    </a>
                  </h3>
                  <div className="collapse show" id="widget-2">
                    <div className="widget-body">
                      <div className="filter-items">
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-1"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-1"
                            >
                              XS
                            </label>
                          </div>
                        </div>
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-2"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-2"
                            >
                              S
                            </label>
                          </div>
                        </div>
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-3"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-3"
                            >
                              M
                            </label>
                          </div>
                        </div>
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-4"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-4"
                            >
                              L
                            </label>
                          </div>
                        </div>
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-5"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-5"
                            >
                              XL
                            </label>
                          </div>
                        </div>
                        <div className="filter-item">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="size-6"
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="size-6"
                            >
                              XXL
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <a
                      data-toggle="collapse"
                      href="#widget-3"
                      role="button"
                      aria-expanded="true"
                      aria-controls="widget-3"
                    >
                      Colour
                    </a>
                  </h3>
                  <div className="collapse show" id="widget-3">
                    <div className="widget-body">
                      {/* <div className="filter-colors"> */}
                      {/* <a href="#" style="background: #b87145;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #f0c04a;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #333333;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #cc3333;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #3399cc;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #669933;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #f2719c;"><span className="sr-only">Color Name</span></a>
                                                <a href="#" style="background: #ebebeb;"><span className="sr-only">Color Name</span></a>
                                            </div> */}
                    </div>
                  </div>
                </div>

                <div className="widget widget-collapsible">
                  <h3 className="widget-title">
                    <a
                      data-toggle="collapse"
                      href="#widget-5"
                      role="button"
                      aria-expanded="true"
                      aria-controls="widget-5"
                    >
                      Price
                    </a>
                  </h3>
                  <div className="collapse show" id="widget-5">
                    <div className="widget-body">
                      <div className="filter-price">
                        <div className="filter-price-text">
                          Price Range:
                          <span id="filter-price-range">$0 - $750</span>
                        </div>
                        <div
                          id="price-slider"
                          className="noUi-target noUi-ltr noUi-horizontal"
                        >
                          <div className="noUi-base">
                            <div className="noUi-connects">
                              <div className="noUi-connect"></div>
                            </div>
                            <div className="noUi-origin">
                              <div
                                className="noUi-handle noUi-handle-lower"
                                data-handle="0"
                                tabindex="0"
                                role="slider"
                                aria-orientation="horizontal"
                                aria-valuemin="0.0"
                                aria-valuemax="550.0"
                                aria-valuenow="0.0"
                                aria-valuetext="$0"
                              >
                                <div className="noUi-touch-area"></div>
                                <div className="noUi-tooltip">$0</div>
                              </div>
                            </div>
                            <div className="noUi-origin">
                              <div
                                className="noUi-handle noUi-handle-upper"
                                data-handle="1"
                                tabindex="0"
                                role="slider"
                                aria-orientation="horizontal"
                                aria-valuemin="200.0"
                                aria-valuemax="1000.0"
                                aria-valuenow="750.0"
                                aria-valuetext="$750"
                              >
                                <div className="noUi-touch-area"></div>
                                <div className="noUi-tooltip">$750</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </>
  );
};
export default ListProducts;
