export const WishList = () => {
    return (
        <>
            <div className="page-header text-center" style={{ backgroundImage: "url('assets/images/page-header-bg.jpg')" }}>
                <div className="container">
                    <h1 className="page-title">Wishlist<span>Shop</span></h1>
                </div>
            </div>
            <div className="page-content">
                <div className="container">
                    <table className="table table-wishlist table-mobile">
                        <thead>
                            <tr>
                                <th>Product</th> 
                                <th>Price</th>
                                <th>Stock Status</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td className="product-col">
                                    <div className="product">
                                        <figure className="product-media">
                                        <a href="/DetailedProduct/0N9A1032a">
															<img src="/img/products/0N9A1032a.jpg" alt="Product image"/>
														</a>
                                        </figure>

                                        <h3 className="product-title">
                                        <a href="/DetailedProduct/0N9A1032a">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                                        </h3>
                                    </div>
                                </td>
                                <td className="price-col">$250.00</td>
                                <td className="stock-col"><span className="in-stock">In stock</span></td>
                                <td className="action-col">
                                    <div className="dropdown">
                                        <button className="btn btn-block btn-outline-primary-2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="icon-list-alt"></i>Select Options
                                        </button>

                                        {/* <div className="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 40px, 0px);">
                                            <a className="dropdown-item" href="#">First option</a>
                                            <a className="dropdown-item" href="#">Another option</a>
                                            <a className="dropdown-item" href="#">The best option</a>
                                        </div> */}
                                    </div>
                                </td>
                                <td className="remove-col">
                                    <button className="btn-remove"><i className="icon-close"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className="product-col">
                                    <div className="product">
                                        <figure className="product-media">
                                        <a href="/DetailedProduct/0N9A0423a">
															<img src="/img/products/0N9A0423a.jpg" alt="Product image"/>
														</a>
                                        </figure>

                                        <h3 className="product-title">
                                        <a href="/DetailedProduct/0N9A0423a">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                                        </h3>
                                    </div>
                                </td>
                                <td className="price-col">$250.00</td>
                                <td className="stock-col"><span className="in-stock">In stock</span></td>
                                <td className="action-col">
                                    <button className="btn btn-block btn-outline-primary-2"><i className="icon-cart-plus"></i>Add to Cart</button>
                                </td>
                                <td className="remove-col">
                                    <button className="btn-remove"><i className="icon-close"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <td className="product-col">
                                    <div className="product">
                                        <figure className="product-media">
                                        <a href="DetailedProduct/0N9A0605a">
															<img src="/img/products/0N9A0605a.jpg" alt="Product image"/>
														</a>
                                        </figure>

                                        <h3 className="product-title">
                                        <a href="/DetailedProduct/0N9A0605a">Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                                        </h3>
                                    </div>
                                </td>
                                <td className="price-col">$250.00</td>
                                <td className="stock-col"><span className="out-of-stock">Out of stock</span></td>
                                <td className="action-col">
                                    <button className="btn btn-block btn-outline-primary-2 disabled">Out of Stock</button>
                                </td>
                                <td className="remove-col">
                                    <button className="btn-remove"><i className="icon-close"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="wishlist-share">
                        <div className="social-icons social-icons-sm mb-2">
                            <label className="social-label">Share on:</label>
                            <a href="#" className="social-icon" title="Facebook" target="_blank"><i className="icon-facebook-f"></i></a>
                            <a href="#" className="social-icon" title="Twitter" target="_blank"><i className="icon-twitter"></i></a>
                            <a href="#" className="social-icon" title="Instagram" target="_blank"><i className="icon-instagram"></i></a>
                            <a href="#" className="social-icon" title="Youtube" target="_blank"><i className="icon-youtube"></i></a>
                            <a href="#" className="social-icon" title="Pinterest" target="_blank"><i className="icon-pinterest"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default WishList;

